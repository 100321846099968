import PropTypes from 'prop-types';
import { Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Col, Modal, Row, Tooltip, Checkbox, Divider } from 'antd';
import { get, map } from 'lodash';
import { CloudDownloadOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { APP_RELEASE_STATUS, APPLICATION_TYPE, FORMS } from '../../../utils/enums';
import TextField from '../../../atoms/form/TextField';
import SelectField from '../../../atoms/form/SelectField';
import validateForm from './validateForm';
import { history } from '../../../utils/history';
import { deleteGroup, upgradeGroup } from '../../../redux/groups/actions';
import { getErrorFieldsLabel } from '../../../utils/helpers';
import DetailHeader from '../../../components/DetailHeader';
import { ApplicationModel } from '../../../types/applications/redux';
import {
  FilterApplicationOption,
  GroupFormModel,
  GroupFormProps,
} from '../../../types/groups/page';
import { changeGroupDevicesState } from '../../../redux/devices/actions';
import SendCommandComponent from '../../../components/SendCommandComponent';
import SwitchField from '../../../atoms/form/SwitchField';
import DeviceOnGroupsComponent from '../../../components/DeviceOnGroupsComponent';

const GroupForm = (props: GroupFormProps & InjectedFormProps<GroupFormModel, GroupFormProps>) => {
  const { handleSubmit, onMoveHandle, invalid, pristine, isCreate, devicesDataSource, id } = props;
  const { t } = useTranslation(['pages', 'components', 'errors']);

  const dispatch = useDispatch();
  const fieldValues = useSelector((state) => getFormValues(FORMS.GROUP_FORM)(state));
  const applications = useSelector<DefaultRootState, ApplicationModel[]>((state) =>
    get(state, 'applications.list.tableList'),
  );
  const formErrors = useSelector(getFormSyncErrors(FORMS.GROUP_FORM));

  const mapToSelectOptions = (apps: ApplicationModel[]) => {
    return map(apps, (item) => ({
      value: item.id,
      label: `${item.name}  | v. ${item.code} | ${
        item.isRelease ? APP_RELEASE_STATUS.RELEASE : APP_RELEASE_STATUS.DEVELOP
      }`,
    }));
  };

  const appApplications = applications.filter((ap) => ap.type === APPLICATION_TYPE.APPLICATION);
  const auApplications = applications.filter(
    (ap) => ap.type === APPLICATION_TYPE.APPLICATION_UPDATER,
  );

  const appApplicationsOptions = mapToSelectOptions(appApplications);
  const auApplicationsOptions = mapToSelectOptions(auApplications);

  const handleRemove = () => {
    Modal.confirm({
      title: t('pages:group.delete'),
      icon: <DeleteOutlined />,
      okText: t('components:delete'),
      cancelText: t('components:cancel'),
      okType: 'danger',
      onOk: () => {
        dispatch(
          deleteGroup(get(fieldValues, 'id'), () => {
            history.push('/groups');
          }),
        );
      },
    });
  };

  const handleUpgrade = () => {
    let forceUpdate = false;
    Modal.confirm({
      title: t('pages:group.upgrade'),
      icon: <CloudDownloadOutlined />,
      okText: t('components:upgrade'),
      cancelText: t('components:cancel'),
      content: (
        <Checkbox
          onChange={(e) => {
            forceUpdate = e.target.checked;
          }}
        >
          {t('pages:group.force_update')}
        </Checkbox>
      ),
      onOk: () => {
        dispatch(
          upgradeGroup(get(fieldValues, 'id'), forceUpdate, () => {
            history.push('/groups');
          }),
        );
      },
    });
  };

  const handleChangeState = (command: string) => {
    Modal.confirm({
      title: t('pages:device.change_state_confirmation'),
      icon: <SaveOutlined />,
      okText: t('components:change_state'),
      cancelText: t('components:cancel'),
      onOk: () => {
        dispatch(changeGroupDevicesState(get(fieldValues, 'id'), command));
      },
    });
  };

  const getFieldLabel = (field: string) => {
    switch (field) {
      case 'name':
        return t('pages:group.name');
      case 'appApplicationID':
        return t('pages:group.application.app');
      case 'auApplicationID':
        return t('pages:group.application.au');
      default:
        return field;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={18} className={'grid'}>
          <div className={'flex direction-col justify-start main-content'}>
            <DetailHeader title={isCreate ? t('pages:group.create') : t('pages:group.detail')} />

            <Field
              name={'name'}
              component={TextField}
              label={t('pages:group.name')}
              required={true}
            />
            <Field
              name={'applicationID'}
              component={SelectField}
              required={true}
              label={t('pages:group.application.app')}
              options={appApplicationsOptions}
              showSearch={true}
              filterOption={(input: string, option: FilterApplicationOption) =>
                get(option, 'label', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <Field
              name={'auApplicationID'}
              component={SelectField}
              required={true}
              label={t('pages:group.application.au')}
              options={auApplicationsOptions}
              showSearch={true}
              filterOption={(input: string, option: FilterApplicationOption) =>
                get(option, 'label', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <Field
              name={'isProduction'}
              component={SwitchField}
              label={t('pages:group.isProduction')}
              checked={false}
              required={true}
            />
            <DeviceOnGroupsComponent
              onMove={onMoveHandle}
              groupId={id?.toString()}
              devicesDataSource={devicesDataSource}
            />
          </div>
        </Col>
        <Col span={6} className={'grid'}>
          <div className={'flex direction-col justify-start sidebar-content'}>
            <div className={'flex direction-col justify-center'}>
              {!isCreate && (
                <Button
                  icon={<CloudDownloadOutlined />}
                  onClick={() => handleUpgrade()}
                  disabled={!pristine || invalid}
                  type={'primary'}
                  style={{ margin: '20px' }}
                >
                  {t('components:upgrade')}
                </Button>
              )}

              {!isCreate && <SendCommandComponent onHandleCommand={handleChangeState} />}
              <Divider />
              <Tooltip
                title={
                  invalid
                    ? `${t('errors:all_field_is_required')}: ${getErrorFieldsLabel(
                        formErrors as string[],
                        getFieldLabel,
                      )}`
                    : null
                }
                placement={'bottom'}
              >
                <Button
                  icon={<SaveOutlined />}
                  onClick={handleSubmit}
                  disabled={pristine || invalid}
                  type={'primary'}
                  style={{ margin: '20px' }}
                >
                  {t('components:save')}
                </Button>
              </Tooltip>
              {!isCreate && (
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove()}
                  style={{ margin: '20px' }}
                  danger={true}
                >
                  {t('components:delete')}
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </form>
  );
};

GroupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isCreate: PropTypes.bool,
  devicesDataSource: PropTypes.array.isRequired,
};

GroupForm.defaultProps = {
  isCreate: false,
};

export default reduxForm<GroupFormModel, GroupFormProps>({
  form: FORMS.GROUP_FORM,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  validate: validateForm,
})(GroupForm);
